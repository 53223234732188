import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { FuseSearchBarModule, FuseShortcutsModule } from '@fuse/components';
import { FuseSharedModule } from '@fuse/shared.module';
import { ToolbarComponent } from 'app/layout/components/toolbar/toolbar.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatDividerModule } from '@angular/material/divider';
import { MatTooltipModule } from '@angular/material/tooltip';
import { QuickCreateModule } from 'app/main/properties/quick-create/quick-create.module';
import { CommercialsQuickCreateModule } from 'app/main/commercials/commercials-quick-create/commercials-quick-create.module';
import { MeatingCreateModalModule } from 'app/main/activities/meetings/meeting-create-modal/meating-create-modal.module';
import { ViewingToursModalModule } from 'app/main/activities/viewingTours/viewing-tours-modal/viewing-tours-modal.module';
import { TasksCreateModalModule } from 'app/main/activities/tasks/tasks-create-modal/tasks-create-modal.module';
import { ScreenHelpModule } from 'app/components/screen-help/screen-help.module';
import { GenerateRegisterTokenModule } from '../generate-register-token/generate-register-token.module';
@NgModule({
    declarations: [
        ToolbarComponent
    ],
    imports     : [
        RouterModule,
        MatButtonModule,
        MatIconModule,
        MatMenuModule,
        MatToolbarModule,
        FuseSharedModule,
        FuseSearchBarModule,
        FuseShortcutsModule,
        MatSnackBarModule,
        MatDividerModule,
        QuickCreateModule,
        CommercialsQuickCreateModule,
        MeatingCreateModalModule,
        ViewingToursModalModule,
        TasksCreateModalModule,
        ScreenHelpModule,
        GenerateRegisterTokenModule,
        MatTooltipModule
    ],
    exports     : [
        ToolbarComponent
    ]
})
export class ToolbarModule
{
}
