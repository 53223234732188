import { FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { environment } from 'environments/environment';

export const GLOBALS = {

  loaders: { page: false, box: false },

  pageActions: { create: 'create', update: 'update', view: 'view', correction: 'correction' },

  animations: {
    scale: { value: '*', params: { delay: '100ms', scale: '0.2' } },
    y: { value: '*', params: { delay: '100ms', y: '-25px' } },
    x: { value: '*', params: { delay: '100ms', x: '-25px' } },
    matTable: { value: '50' },
    icon: { value: '*', params: { delay: '50ms', scale: '0.2' } },
    listTitle: { value: '*', params: { delay: '100ms', x: '-25px' } }
  },

  pagination: {
    pageSize: 25,
    pageSizeOptions: [25, 50, 75, 100]
  },

  // Invoice status
  invoiceStatuses: { active: 'active', inactive: 'inactive' },
  invoiceOrQuote: { invoice: 'invoice', quote: 'quote' },

  // Lead status
  contactStatuses: [
    {name:'inactive', value:'inactive'},
    {name:'lead', value:'lead'},
    {name:'prospect', value:'prospect'},
    {name:'client', value:'client'},
    {name:'previous client', value:'previous client'},
    {name:'free pool', value:'free pool'}
  ],

  /** tinyMce Config */
  tinyMce: {
    base_url: '/tinymce',
    suffix: '.min',
    images_upload_url: environment.yiiUrl + 'cms-pages/post-image',
    images_upload_handler: (blobInfo, success, failure): any => {
      let xhr: XMLHttpRequest;
      let formData: FormData;
      xhr = new XMLHttpRequest();
      xhr.withCredentials = false;
      xhr.open('POST', environment.yiiUrl + 'cms-pages/post-image');
      xhr.onload = () => {
        let json: { link: any; };
        if (xhr.status !== 200) {
          failure('HTTP Error: ' + xhr.status);
          return;
        }
        json = JSON.parse(xhr.responseText);
        if (!json || typeof json.link !== 'string') {
          failure('Invalid JSON: ' + xhr.responseText);
          return;
        }
        success(json.link);
      };
      formData = new FormData();
      formData.append('file', blobInfo.blob(), blobInfo.filename());
      formData.append('model_id', 'root');
      formData.append('model_name', 'any');
      xhr.send(formData);
    },
    setup: (editor) => {
      editor.on('focus', (e) => { });
    },
    automatic_uploads: true,
    height: 250,
    toolbar_mode: 'sliding',
    menubar: false,
    plugins: [
      'advlist autolink lists link media image charmap print',
      'preview anchor searchreplace visualblocks code',
      'fullscreen insertdatetime media table paste',
      'help wordcount',
      'image code',
      'fullpage',
      'print'
    ],
    toolbar:
      'undo redo | formatselect | fontselect | fontsizeselect | forecolor | bold italic | \
      alignleft aligncenter alignright alignjustify | \
      bullist numlist outdent indent | print | fullpage | image code | link | media',
    font_formats:
      "Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; DM Serif Display=dm serif display,sans-serif; Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; Montserrat=montserrat,sans-serif; Open Sans=open sans; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva; Webdings=webdings; Wingdings=wingdings,zapf dingbats",
    content_style:
      "@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400&display=swap');@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap');@import url('https://fonts.googleapis.com/css2?family=DM+Serif+Display&display=swap')",
  },
};

export class CustomValidators {

  static validateDate: ValidatorFn = (control: FormGroup): ValidationErrors | null => {
    /// TODO: Implement some better validation logic
    const invalid = new Date(control.get('period_from').value) > new Date(control.get('period_to').value);

    /// TODO: Implement some logic to mark controls dirty if is necessary.
    return invalid ? { invalidDate: true } : null;
  }
}
